<template>
  <div>
    <div class="">
        <vs-textarea class="w-full" rows="6" name="content" v-validate="{required: true, max:1000}" 
            counter="1000" label="Type your message here" v-model="data.content" />
        <span class="text-danger text-sm"  v-show="errors.has('content')">{{ errors.first('content') }}
        </span>
    </div>
    <div class="flex flex-wrap items-center justify-end">
        <vs-button radius color="dark" @click.prevent="popupAttachment = true"  class="inline mr-2" type="line" icon-pack="feather" icon="icon-paperclip"></vs-button>
        <vs-button type="filled" @click.prevent="create" class="mt-2 block">Send</vs-button>
    </div>
    <vs-popup
        @close="initializeAll"
        title="Add Files"
        :active.sync="popupAttachment">
         <order-message-uploader :orderId="orderId" @closeMe="initializeAll" />
      </vs-popup>
  </div>
</template>

<script>
import OrderMessageUploader from './OrderMessageUploader.vue'

export default {
    components:{
        OrderMessageUploader
    },
    props:{
        orderId:{
            type: Number,
            required: true
        }
    },
    data(){
        return{
            popupAttachment: false,
            data:{
                title:"Message sent",
                content:"",
                icon: "MessageSquareIcon",
                order_id: this.orderId,
                type: "ORDER_MESSAGE",
                url: `/orders/${this.orderId}`
            }
        }
    },
    methods:{
        create(){
            this.$validator.validateAll().then(result => {
                if (result) {
                    const formData = new FormData()
                    formData.append('data', JSON.stringify(this.data))
                    this.$vs.loading({ color: "#444", type: "sound"})
                    this.$http.post(`/activities/create`, formData)
                    .then((response) => {
                        this.$vs.loading.close()
                        if(response.data.success){
                            this.$store.commit("orderList/ADD_ACTIVITY", response.data.data.activity)
                            this.initializeAll();
                        }
                    })
                }
            })
        },
        initializeAll(){
            this.data.content = ""
            this.popupAttachment = false
            this.$validator.reset()
        }
    }
}
</script>

<style>

</style>